.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid yellow;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: 100px 0 100px 0
  }
  
.heading {
  text-align: center;
  font-size: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  font-weight: bold;
  color: #f3f3f3;
}

.card{
  background-color: rgb(239, 239, 91);
  margin-bottom: 6%;
  border-radius: 7px;
  padding: 3%;
  text-align: center;
  font-weight: bold;
  color:black;
  
}

.count {
  margin-bottom: 10px;
}

.main-card {
  width: 100%;
}

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media only screen and (min-width: 600px) {
    .card {
      width: 20%;
    }

    .main-card {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }